<template>
	<div>
		<div class="js-copy js-content width_1200">
			<img
				style="width: 100%"
				src="https://res.metaera.hk/resources/assets/images/aboutus/download_bg1.png"
				alt=""
			/>
		</div>
		<div class="download_btn" @click="download()">
			<img
				src="https://res.metaera.hk/resources/assets/images/aboutus/download_btn1.png"
				alt=""
			/>
			<span>立即下载</span>
		</div>
		<div
			style="
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #000000ca;
			"
			v-if="olead == true"
		>
			<img
				style="width: 100%"
				src="https://res.metaera.hk/resources/assets/images/aboutus/download_alert1.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return { osafai: false, olead: false };
	},
	created() {},
	methods: {
		download() {
			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			} else {
				let apkurl = "https://res.metaera.hk/resources/MetaEra_1_2_6.apk";
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || isiOS) {
					//判断是ios还是android
					window.location.href =
						"https://apps.apple.com/cn/app/meta-era/id6450677755"; // 跳AppStore下载地址
				} else if (/(Android)/i.test(navigator.userAgent)) {
					window.location.href = apkurl;
				} else {
					window.location.href = apkurl;
				}
			}
		},
	},
};
</script>

<style scoped>
.js-copy {
	background: #fff;
	margin: 0 auto;
}
@media screen and (max-width: 900px) {
	.download_btn {
		position: absolute;
		top: 70%;
		width: 60%;
		left: 20%;
		text-align: center;
		align-content: center;
		background: black;
		border-radius: 30px;
		height: 60px;
	}
	.download_btn img {
		width: 24px;
		height: auto;
		margin-right: 6px;
	}
	.download_btn span {
		color: white;
		text-align: center;
		align-content: center;
		font-size: 16px;
	}
	.js-copy {
		background: #fff;
		margin: 0 auto;
		margin-top: calc(100vw * -80 / 375);
	}
}
</style>
